import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import wx from "weixin-js-sdk"
import '@/style/common.css'

Vue.use(Vant)
// 挂在vue的原型上方便使用
Vue.prototype.$wx = wx;

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-app']

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
