let addlist = {
    namespaced: true,
    state: {
        addinfo: {},
    },
    mutations:{
        SET_ADDINFO(state,addinfo){
            state.addinfo = addinfo;
        },
        CLEAR_ADDINFO(state){
            state.addinfo = {};
        }
    },
    actions: {
        setAddinfo({commit},addinfo){
            commit('SET_ADDINFO',addinfo);
        },
        clearAddinfo({commit}){
            commit('CLEAR_ADDINFO');
        }
    }
}
export default addlist
