import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/task',
    name: 'task',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/notwx',
    name: 'Not',
    component: () => import('@/views/notwx.vue')
  },
  {
    path: '/binding',
    name: 'binding',
    component: () => import('@/views/phonenum/index.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/Message.vue')
  },
  {
    path: '/pubshare',
    name: 'pubshare',
    component: () => import('@/views/share/share')
  },
  {
    path: '/pubedit',
    name: 'pubedit',
    component: () => import('@/views/share/editshare.vue')
  },
  {
    path: '/jxfw',
    name: 'jxfw',
    component: () => import('@/views/jxfw.vue'),
    meta: {
      title: "建信服务",
      keepAlive: true
    }
  },
  {
    path: '/b',
    name: 'b',
    component: () => import('@/views/new_jxfw.vue')
  },
  {
    path: '/jxpay',
    name: '/jxpay',
    component: () => import('@/views/jxfwVip.vue'),
    meta: {
      title: "建信服务会员",
      keepAlive: true
    }
  },
  {
    path: '/tiyan',
    name: '/tiyan',
    component: () => import('@/views/tiyanVip.vue'),
    meta: {
      title: "建信服务体验会员",
      keepAlive: true
    }
  },
  /*{
    path: '/edits',
    name: 'edits',
    component: () => import('@/components/showList.vue')
  },*/
  {
    path: '/su',
    name: 'su',
    component: () => import('@/components/success.vue')
  },
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/newhome.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test.vue')
  },
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//不是微信跳转
/*router.beforeEach((to,from,next)=>{
  const ua = navigator.userAgent.toLowerCase();
    if (to.path!=='/notwx' && !(ua.match(/MicroMessenger/i)=="micromessenger")){
      next("/notwx");
    }else {
      next();
    }

});*/

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined?'四序——企业管理专家-成都四小牛科技有限公司':to.meta.title
  /*if (to.meta.requireAuth) {
    let token = Cookies.get('access_token');
    let anonymous = Cookies.get('user_name');
    if (token) {

      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })

    }
  }*/
  next();
})


export default router
