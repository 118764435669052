/****
 * 所有需要访问vuex中的属性，都需使用getter来获取
 *
 *
 */
let getters = {
    addinfo: state => state.addlist.addinfo,
   /* timeinfo: state => state.time.timeinfo,
    time: state => state.time.time,
    date: state => state.time.date,
    timedate: state => state.time.timedate,*/
    //...
}
export default getters
